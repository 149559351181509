<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-data-table
              :items="coeffs"
              :headers="headers"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Коэффициенты для акций</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="400px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Новая запись
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row justify="center">
                            <v-col
                              cols="12"
                            >
                              <v-date-picker
                                v-model="editedItem.dates"
                                :allowed-dates="allowedDates"
                                label="Даты"
                                range
                              ></v-date-picker>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <v-text-field
                                v-model="editedItem.coeff"
                                label="Коэффициент"
                                placeholder="Коэффициент"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-btn
                          text
                          @click="close"
                        >
                          Отменить
                        </v-btn>
                        <v-btn
                          :disabled="editedItem.hasOwnProperty('dates') && editedItem.dates.length < 2"
                          color="success"
                          @click="save"
                        >
                          Сохранить
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Точно хочешь удалить?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">Да, удаляем</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.created_by="{ item }">
                {{ getManager(item) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  {{ mdiPencil }}
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  {{mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {mdiDelete, mdiPencil} from '@mdi/js'
import moment from 'moment'
import _ from 'lodash'
import httpClient from '@/services/http.service'
import qs from 'qs'

require('moment/locale/ru')

export default {
  name: 'Index',
  data(){
    return{
      mdiDelete, mdiPencil,
      coeffs: [],
      headers:[
        {text: 'Начало', value: 'start'},
        {text: 'Конец', value: 'end'},
        {text: 'Коэффициент', value: 'coeff'},
        {text: 'Кто такой', value: 'created_by'},
        {text: 'Действия', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedItem:{dates: []},
      editedIndex: -1,
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Новая запись' : 'Редактирование записи'
    },
    managers(){
      return JSON.parse(JSON.stringify(this.$store.state.users))
    }
  },
  mounted() {
    httpClient.get('index-coeff').then(({data})=>{this.coeffs = data})
  },
  methods:{
    getManager(user){
      const manager = this.managers.find(x => x.id == user.id)
      return manager.name
    },
    allowedDates(date) {
      let allowed = true
      const m = moment(date, 'YYYY-MM-DD')
      _.each(this.coeffs, (k, i) => {
        if (m.isBetween(moment(k.start, 'YYYY-MM-DD H:m:s'), moment(k.end, 'YYYY-MM-DD H:m:s'))){
          if(this.editedIndex !== i){ //если это даты этой же записи Coeff, то её можно и поменять
            allowed = false
          }
        }
      })
      return allowed
    },
    editItem(item) {
      item.dates = [item.start, item.end]
      this.editedIndex = this.coeffs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close(){
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItem(item) {
      this.editedIndex = this.coeffs.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.coeffs.splice(this.editedIndex, 1)
      httpClient.delete(`index-coeff/${this.editedItem.id}`)
      this.closeDelete()
    },
    save() {
      if (this.editedIndex > -1) {
        httpClient.put(`index-coeff/${this.editedItem.id}`, qs.stringify({start: this.editedItem.dates[0], end: this.editedItem.dates[1], coeff: this.editedItem.coeff})).then(({data})=>{
          Object.assign(this.coeffs[this.editedIndex], data)
        })
        // Object.assign(this.coeffs[this.editedIndex], this.editedItem)
      } else {
        httpClient.post('index-coeff', qs.stringify({start: this.editedItem.dates[0], end: this.editedItem.dates[1], coeff: this.editedItem.coeff})).then(({data})=>{
          this.coeffs.push(data)
        })

      }
      this.close()
    },
  }
}
</script>

<style scoped>

</style>
